<template>
  <div class="goods-detail">
    <v-app>
      <v-container fluid class="mb-9">
        <v-card class="pt-8 mt-6 mb-16">
          <v-app-bar class="navbar" app height="50px">
            <div class="header-title">
              <div class="d-flex align-center">
                <v-btn
                  icon
                  small
                  dark
                  class="mr-2 btn-prev"
                  @click="$router.back()"
                  @click.prevent="quantity = 0.1"
                >
                  <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                <h1>รายละเอียด</h1>
              </div>
              <v-btn
                icon
                dark
                class="pt-2"
                @click.prevent="$router.push('/cart')"
              >
                <v-badge
                  right
                  color="red"
                  overlap
                  :content="`${this.$store.getters.getShoppingCart.length}`"
                >
                  <v-icon class="ma-1">mdi-cart</v-icon>
                </v-badge>
              </v-btn>
            </div>
          </v-app-bar>

          <div class="wrapper-info-product">
            <div class="info-image mt-1">
              <v-img
                :aspect-ratio="16 / 9"
                :src="this.$store.getters.getGoodsDetail.imagePath"
              ></v-img>
            </div>
            <div class="info-info px-2 mt-2 pb-2">
              <h1>{{ this.$store.getters.getGoodsDetail.itemName }}</h1>
              <h1>{{ this.$store.getters.getGoodsDetail.itemNameTh }}</h1>
            </div>
              <div class="px-2 pb-2">
              <v-divider class="my-2"></v-divider>
              <p>ราคา: {{$store.getters.getGoodsDetail.price[0].price}}/{{$store.getters.getGoodsDetail.itemUnit}}</p>

              </div>
            <!-- <div class="info-supplier px-2 pb-2">
              <div class="info-supplier-title">
                โปรดเลือกSupplier<span class="red--text">*</span>
              </div>
              <div class="wrapper-supplier-list">
                <div
                  v-for="(supplier, index) in $store.getters.getGoodsDetail
                    .price"
                  :key="index"
                  active-class="primary"
                  class="card-supplier-list d-flex justify-space-between align-center"
                  @click.prevent="
                    selectSupplier(supplier.supplier.id, supplier)
                  "
                  :class="{ 'card-active': supId === supplier.supplier.id }"
                >
                  <div class="supplier-name">
                    {{ supplier.supplier.supplierName }}
                  </div>
                  <div class="supplier-price text-center">
                    {{ supplier.price }} บาท/{{
                      $store.getters.getGoodsDetail.itemUnit
                    }}
                    <div v-if="supplier.vat > 0">(VAT {{ supplier.vat }}%)</div>
                  </div>
                </div>
              </div>
            </div> -->
            <!-- <div class="info-remark px-2">
              <label for="remark">หมายเหตุ <span style="color: #444; font-size: 12px">(ไม่จำเป็นต้องกรอก)</span></label>
              <v-text-field
                outlined
                dense
                class="mt-1"
              ></v-text-field>
            </div> -->
          </div>
        </v-card>
      </v-container>
      <div class="add-to-cart">
        <div class="bottom-quantity mt-3">
          <div class="d-flex align-center justify-center">
            <v-btn
              color="primary"
              fab
              x-small
              outlined
              @click.prevent="delQuantity"
              style="background: #fff"
            >
              <v-icon>mdi-minus</v-icon>
            </v-btn>
            <input
              type="text"
              v-model="quantity"
              @input="onlyNumbers"
            />
            <v-btn color="primary" fab x-small @click.prevent="addQuantity">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
          <v-btn
            class="mt-3"
            block
            color="primary"
            @click.prevent="addToCart()"
          >
            เพิ่มลงรถเข็น
          </v-btn>
        </div>
      </div>
    </v-app>
  </div>
</template>

<script>
import HeaderTitlePage from "@/components/HeaderTitlePage.vue";
import Swal from "sweetalert2";
export default {
  name: "goods-detail",
  components: {
    HeaderTitlePage,
  },
  data() {
    return {
      quantity: 0.1,
      supId: 1,
      // supData: this.$store.getters.getGoodsDetail.price[0],
    };
  },
 
  methods: {
    onlyNumbers: function() {
       this.quantity = this.quantity.replace(/[^0-9.]/g,'');
    },
    // selectSupplier(id, supplier) {
    //   console.log(id);
    //   this.supId = id;
    //   this.supData = supplier;
    //   console.log(supplier);
    // },
    addQuantity() {
      this.quantity = parseFloat(this.quantity)
      this.quantity = this.quantity + 0.1
      this.quantity = this.quantity.toFixed(2)
      this.quantity = parseFloat(this.quantity)
    },
    delQuantity() {
      this.quantity = parseFloat(this.quantity)
      if (this.quantity > 0.1) {
        this.quantity = this.quantity - 0.1
        this.quantity = this.quantity.toFixed(2)
        this.quantity = parseFloat(this.quantity)
      }
    },
    addToCart() {
      //console.log(this.$store.getters.getGoodsDetail);

      if (this.supId !== null) {
        let sumTotal = parseFloat(this.$store.getters.getGoodsDetail.price[0].price) * parseFloat(this.quantity);;
        let cartObj = {
          id: this.$store.getters.getGoodsDetail.id,
          imagePath: this.$store.getters.getGoodsDetail.imagePath,
          name: this.$store.getters.getGoodsDetail.itemName,
          unitPrice: parseFloat(this.$store.getters.getGoodsDetail.price[0].price),
          unit: this.$store.getters.getGoodsDetail.itemUnit,
          quantity: this.quantity,
          supplierID: this.supId,
          vat: 0,
          summary: sumTotal,
          summaryVat: sumTotal,
          note: "",
        };
        console.log(cartObj);
        this.$store.dispatch("setShoppingCart", cartObj);
        this.quantity = 0.1;
        // this.supId = null;
        // this.supData = null;
        this.$router.back();
      } else if (this.supId === null) {
        Swal.fire({
          icon: "error",
          title: "เลือกSupplierที่ต้องการสั่งซื้อก่อน",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    },
  },
};
</script>

<style scoped>
.btn-back {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 24px !important;
  height: 24px !important;
  z-index: 1;
}
.info-image {
  position: relative;
}
/* .info-image img {
  width: 100%;
  height: 100%;
}  */
.info-info h1 {
  font-size: 17px;
  font-weight: 500;
}
.bottom-quantity {
  position: fixed;
  background: rgb(255, 255, 255, 0.8);
  width: 100%;
  bottom: 0;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1);
  padding: 12px;
}
.bottom-quantity input {
  border: 1px solid#3280d5;
  margin: 0 10px;
  border-radius: 4px;
  outline: none;
  text-align: center;
  font-size: 20px;
  width: 100px;
  font-weight: 600;
  color: #3280d5;
  background: #fff;
}
.info-supplier-title {
  font-size: 16px;
  margin: 5px 0;
}
.card-supplier-list {
  background: #fff;
  border: 1px solid rgb(209, 209, 209);
  border-radius: 3px;
  height: 55px;
  width: 100%;
  padding: 0 10px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 8px;
}
.card-supplier-list:last-child {
  margin-bottom: 0px;
}
.card-active {
  background: #2196f3 !important;
  color: #fff !important;
}
.wrapper-supplier-list {
  width: 100%;
  /* background: rosybrown; */
  max-height: 220px;
  overflow: scroll;
  background: #f0f2f5;
}
.supplier-name,
.supplier-price {
  font-size: 12px;
}
.supplier-price {
  white-space: nowrap;
}

.navbar {
  position: absolute !important;
  width: 95%;
  top: -24px !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  background: #2e2253 !important;
  border-radius: 3px !important;
}
.header-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-title h1 {
  color: #fff;
  font-weight: 400;
  font-size: 19px;
}
</style>